<template>
  <div>
    <CyDetails
      :item-id="teamCanonical"
      :on-save="onSave"
      :on-cancel="onCancel"
      :on-delete="$toggle.showDeleteModal"
      :can-cancel="canCancel"
      :can-save="canSave"
      :loading="loading"
      :saving="saving"
      :deleting="deleting"
      :is-owner="isOwner"
      :is-read-only="!canUpdateTeam">
      <template slot="details_form">
        <CyAlert
          theme="error"
          :content="errors.team"/>

        <v-text-field
          ref="nameField"
          v-model="$v.formContent.name.$model"
          :disabled="!canUpdateTeam"
          :readonly="!canUpdateTeam"
          :label="$t('forms.fieldName')"
          :error-messages="nameErrors"
          item-value="name"
          required
          class="required-field"
          @blur="$v.formContent.name.$touch()"/>

        <CySelectOwner
          :key="resetAt"
          v-model="$v.formContent.owner.$model"
          :readonly="!canUpdateTeam"
          :disabled="!canUpdateTeam"
          :current-owner="formContent.owner"/>

        <v-autocomplete
          v-model="$v.formContent.roles.$model"
          :items="availableRoles"
          :label="$t('forms.fieldDefaultRoles')"
          :hint="$t('formTeamRoleHint')"
          :menu-props="{
            bottom: true,
            contentClass: 'no-active-indicators',
          }"
          :disabled="!canUpdateTeam"
          :readonly="!canUpdateTeam"
          chips
          multiple
          deletable-chips
          persistent-hint
          return-object
          item-text="name"
          item-value="canonical"
          @blur="$v.formContent.roles.$touch()"/>
      </template>

      <!--
        Team Members datatable
      -->
      <template
        v-if="!$isCreationRoute"
        slot="details_secondaryContentFullWidth">
        <CyAlert
          v-if="!$cycloid.permissions.canDisplay('GetTeamMembers', teamCanonical)"
          :content="$t('noTeamMembersAccess')"/>
        <CyTeamMembers
          v-else
          :team-canonical="teamCanonical"
          class="pa-1"/>
      </template>
    </CyDetails>

    <!--
      Delete Team Dialog
    -->
    <CyModal
      v-if="showDeleteModal"
      :header-title="$t('confirmDeleteHeader')"
      :action-btn-func="onDelete"
      :cancel-btn-func="() => $toggle.showDeleteModal(false)"
      :action-btn-text="$t('forms.btnDelete')"
      modal-type="delete"
      small>
      <p>{{ $t('confirmDeleteSentence') }}</p>
      <v-col
        class="text-center">
        <h3 v-if="team">
          {{ team.name }}
        </h3>
      </v-col>
    </CyModal>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import CyDetails from '@/components/details.vue'
import CySelectOwner from '@/components/select-owner.vue'
import CyTeamMembers from '@/components/team-members.vue'
import { constructBreadcrumb, displayName, getOwnerUsername } from '@/utils/helpers'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'CyPageTeam',
  components: {
    CyDetails,
    CyTeamMembers,
    CySelectOwner,
  },
  breadcrumb () {
    const { $isCreationRoute, team } = this
    const header = $isCreationRoute
      ? this.$t('addTeam')
      : team?.name

    return constructBreadcrumb(this.$options.name, header, [
      {
        label: this.$t('routes.teams'),
        name: 'teams',
      },
    ])
  },
  header () {
    const { $isCreationRoute, team } = this

    if ($isCreationRoute) return { title: this.$t('addTeam') }
    if (!team) return {}

    const { name: title, created_at: createdAt, updated_at: updatedAt } = team

    return { title, createdAt, updatedAt }
  },
  props: {
    teamCanonical: {
      type: String,
      default: '',
    },
  },
  validations: {
    formContent: {
      name: { required },
      roles: {},
      owner: {},
    },
  },
  data: () => ({
    loading: true,
    saving: false,
    deleting: false,
    showDeleteModal: false,
    toDelete: [],
    formContent: {
      name: '',
      roles: [],
      owner: null,
    },
    resetAt: Date.now(),
  }),
  computed: {
    ...mapState('organization', {
      availableRoles: (state) => state.available.roles,
      availableMembers: (state) => state.available.members,
    }),
    ...mapState('organization/team', {
      errors: (state) => state.errors,
    }),
    ...mapGetters('organization/team', [
      'team',
    ]),
    nameErrors () {
      const errors = []
      const { $dirty, required } = this.$v.formContent.name
      if (!$dirty) return errors
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      return errors
    },
    canCancel () {
      return this.$hasDataChanged('formContent')
    },
    canSave () {
      return this.$isCreationRoute
        ? !this.$v.$invalid
        : !this.$v.$invalid && this.canCancel
    },
    canUpdateTeam () {
      return this.$cycloid.permissions.canDisplay('UpdateTeam', this.teamCanonical)
    },
    isOwner () {
      return this.isEntityOwner(this.formContent)
    },
    hasOwnerChanged () {
      if (this.$isCreationRoute || _.isEmpty(this.formContent?.owner)) return false
      return !_.isEqual(
        getOwnerUsername(this.formContent?.owner),
        getOwnerUsername(this.team?.owner),
      )
    },
  },
  async mounted () {
    await this.FETCH_AVAILABLE({ keyPath: 'members' })
    await this.fetchTeamAndRoles()
    if (this.$isCreationRoute) this.setInitialOwner()
  },
  beforeDestroy () {
    this.RESET_TEAM_STATE()
  },
  methods: {
    ...mapActions('organization', [
      'FETCH_AVAILABLE',
    ]),
    ...mapActions('organization/team', [
      'GET_TEAM',
      'CREATE_TEAM',
      'UPDATE_TEAM',
      'DELETE_TEAM',
    ]),
    ...mapMutations('organization/team', [
      'RESET_TEAM_STATE',
    ]),
    setInitialOwner () {
      this.formContent.owner = this.availableMembers.find(({ username }) => username === this.username)
      this.resetAt = Date.now()
      this.$setOriginalData()
    },
    async fetchTeamAndRoles () {
      this.loading = true
      if (!this.$isCreationRoute) await this.getTeam()
      await this.FETCH_AVAILABLE({ keyPath: 'roles' })
      this.loading = false
    },
    async getTeam () {
      const { teamCanonical } = this
      await this.GET_TEAM({ teamCanonical })
      if (!_.isEmpty(this.errors.team) || _.isEmpty(this.team)) return
      this.formContent = _.cloneDeep(this.team)
      this.$setOriginalData()
    },
    async onSave () {
      this.saving = true

      const { $isCreationRoute, $router, username } = this
      const { name, owner, roles } = this.formContent
      const teamCanonical = this.teamCanonical || this.$getSlug(name)

      const team = {
        name,
        owner: $isCreationRoute ? username : owner?.username ?? null,
        canonical: teamCanonical,
        roles_canonical: _.map(roles, 'canonical'),
      }

      if (this.$isCreationRoute) await this.CREATE_TEAM({ team, $router })
      else {
        const successMessage = this.hasOwnerChanged && this.$t('alerts.success.team.reassigned', {
          teamName: team.name,
          owner: displayName(owner),
        })
        await this.UPDATE_TEAM({ team, successMessage })
      }

      if (_.isEmpty(this.errors.team)) this.$setOriginalData()

      this.saving = false
    },
    onCancel () {
      this.toDelete = []
      this.resetAt = Date.now()
      this.$resetData('formContent')
      this.$v.$reset()
    },
    async onDelete () {
      this.deleting = true
      const { formContent: team, $router } = this
      await this.DELETE_TEAM({ team, $router })
      this.deleting = false
    },
  },
  i18n: {
    messages: {
      en: {
        title: '@:routes.team',
        addTeam: 'Create @:team',
        confirmDeleteHeader: 'Delete a @:team',
        confirmDeleteSentence: 'Are you sure that you want to delete this @:team?',
        formTeamRoleHint: 'Default roles for users in this @:team',
        noTeamMembersAccess: `@:youDoNotHavePermissionsTo view @:members of this @:team`,
      },
      es: {
        title: '@:routes.team',
        addTeam: 'Crear @:team',
        confirmDeleteHeader: 'Eliminar @:team',
        confirmDeleteSentence: '¿Está seguro de querer eliminar este @:team?',
        formTeamRoleHint: 'Roles predeterminados de los usuarios de este @:team',
        noTeamMembersAccess: `@:youDoNotHavePermissionsTo para ver a los @:members de este @:team`,
      },
      fr: {
        title: '@:routes.team',
        addTeam: 'Créer une @:team',
        confirmDeleteHeader: `Retirer l'@:team`,
        confirmDeleteSentence: 'Êtes vous sûr de vouloir retirer cette @:team ?',
        formTeamRoleHint: 'Rôles par défaut des utilisateurs de cette @:team',
        noTeamMembersAccess: `@:youDoNotHavePermissionsTo afficher les @:members de cette @:team`,
      },
    },
  },
}
</script>

<style lang="scss" scoped>
  .members__table {
    margin-top: 3rem;
  }

  .owner-select {
    margin-top: 1em;
    padding: 16px 0;
  }

  ::v-deep .v-input__append-inner {
    align-self: center;
  }
</style>
