<template>
  <div>
    <CyButton
      icon="add"
      @click="openModal">
      {{ $t('inviteBtn') }}
    </CyButton>

    <CyModal
      v-if="showModal"
      :header-title="$t('inviteToTeam')"
      :loading="saving"
      :action-btn-func="submit"
      :action-btn-disabled="!canSave"
      :action-btn-text="$t('invite')"
      :cancel-btn-func="close"
      modal-type="create">
      <v-form @submit.prevent="submit">
        <CyAlert
          theme="error"
          :content="errors.teamMemberAssign"/>
        <v-autocomplete
          ref="memberInput"
          v-model="$v.members.$model"
          :items="nonTeamMembers"
          :hint="$t('membersHint')"
          :loading="loading"
          :error-messages="membersErrors"
          :label="$t('members')"
          :filter="filterMembers"
          multiple
          hide-selected
          chips
          auto-select-first
          persistent-hint
          return-object
          item-text="name"
          item-value="username"
          required
          class="required-field">
          <template #selection="{ item }">
            <CyMemberTag
              :member="item"
              @remove="removeSelection(item)"/>
          </template>
          <template #item="{ item }">
            <CyMember
              :key="item.username"
              :member="item"/>
          </template>
        </v-autocomplete>
      </v-form>
    </CyModal>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import CyMemberTag from '@/components/member-tag.vue'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'CyFormsAddTeamMembers',
  components: {
    CyMemberTag,
  },
  props: {
    teamCanonical: {
      type: String,
      default: '',
    },
  },
  validations: {
    members: { required },
  },
  data: () => ({
    members: [],
    showModal: false,
    saving: false,
    loading: false,
  }),
  computed: {
    ...mapState('organization', {
      teamMembers: (state) => state.available.teamMembers,
    }),
    ...mapState('organization/team', {
      errors: (state) => state.errors,
    }),
    ...mapGetters(['orgMembers']),
    canSave () {
      return !this.$v.$invalid && !this.saving
    },
    nonTeamMembers () {
      return this.orgMembers.filter(({ username }) => !_.map(this.teamMembers, 'username').includes(username))
    },
    membersErrors () {
      const errors = []
      const { $dirty, required } = this.$v.members
      if (!$dirty) return []
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      return errors
    },
  },
  async created () {
    await this.$evaluateUserActions('AssignMemberToTeam')
  },
  methods: {
    ...mapActions('organization', [
      'FETCH_AVAILABLE',
    ]),
    ...mapActions('organization/team', [
      'ASSIGN_TEAM_MEMBERS',
    ]),
    async openModal () {
      this.$setOriginalData()
      if (_.isEmpty(this.orgMembers)) await this.FETCH_AVAILABLE({ keyPath: 'members' })
      this.showModal = true
    },
    async submit () {
      if (!this.canSave) return

      this.saving = true
      const { teamCanonical, members } = this
      const usernames = _.map(members, 'username')

      await this.ASSIGN_TEAM_MEMBERS({ teamCanonical, usernames })
      if (_.isEmpty(this.errors.teamMemberAssign)) this.close({ refreshTeam: true })

      this.saving = false
    },
    close ({ refreshTeam = false } = {}) {
      this.showModal = false
      this.$resetData()
      this.$v.$reset()
      if (refreshTeam) this.$emit('refresh-team')
    },
    removeSelection (member) {
      const index = _.findIndex(this.members, ({ id }) => id === member.id)
      this.$delete(this.members, index)
    },
    filterMembers ({ username, given_name: firstName, family_name: lastName, email }, queryText) {
      return _.isEmpty(queryText)
        ? true
        : _.some([
          username.toLowerCase().includes(queryText.toLowerCase()),
          firstName.toLowerCase().includes(queryText.toLowerCase()),
          lastName.toLowerCase().includes(queryText.toLowerCase()),
          email.toLowerCase().includes(queryText.toLowerCase()),
        ])
    },
  },
  i18n: {
    messages: {
      en: {
        inviteBtn: 'Invite new members',
        inviteToTeam: 'Invite members to the @:team',
        members: '@:routes.members',
        membersHint: 'Members of your organization',
      },
      es: {
        inviteBtn: 'Invite a nuevos miembros',
        inviteToTeam: 'Invite miembros al @:team',
        members: '@:routes.members',
        membersHint: 'Miembros de su organización',
      },
      fr: {
        inviteBtn: 'Invitez de nouveaux membres',
        inviteToTeam: `Inviter des membres dans l'@:team`,
        members: '@:routes.members',
        membersHint: 'Membres de votre organization',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.default-avatar-placeholder {
  width: 40px;
  height: 40px;
  line-height: 40px;
}
</style>
