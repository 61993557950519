<template>
  <div>
    <!--
      Team Members datatable
    -->
    <h3 class="team-members__header">
      {{ $tc('memberCount', teamMembers.length) }}
    </h3>

    <CyAlert
      theme="error"
      :content="errors.unassignMembers"/>

    <CyDataTableYdApi
      ref="cyDataTable"
      :fetch-available="{ keyPath: 'teamMembers', extraParams: [teamCanonical] }"
      :headers="$static.headers"
      :searchable-fields="$static.searchableFields"
      :bulk="hasBulkModeEnabled"
      key-field="username">
      <template #table-cmp-header-actions="{ selected }">
        <CyButton
          v-if="!_.isEmpty(selected)"
          theme="error"
          icon="delete"
          @click="openDeleteModal(selected)">
          {{ $tc('removeMembersBtn', selected.length, { nb: selected.length }) }}
        </CyButton>

        <CyFormsAddTeamMembers
          v-else
          v-has-rights-to="['AssignMemberToTeam', teamCanonical]"
          :team-canonical="teamCanonical"
          @refresh-team="$refs.cyDataTable.retrieveItems({ clearErrors: true })"/>

        <!--
          Delete modal
        -->
        <CyModal
          v-if="showDeleteModal"
          :header-title="$tc('confirmRemoveTitle', toDelete.length)"
          :action-btn-func="onDelete"
          :cancel-btn-func="closeDeleteModal"
          :action-btn-text="$tc('removeMembersBtn', selected.length, { nb: selected.length })"
          modal-type="delete">
          <p>
            {{ $t('forms.cannotBeUndone') }}
            <span v-html="$tc('areYouSure', toDelete.length, { nb: toDelete.length })"/>
          </p>
          <ul class="items-to-delete">
            <li
              v-for="item of toDelete"
              :key="item.username">
              <CyMember :member="item"/>
            </li>
          </ul>
        </CyModal>
      </template>

      <template #table-cmp-body-row="{ props: { item } }">
        <td>
          <CyMember :member="item"/>
        </td>
        <td>
          <CyMember
            v-if="item.invited_by"
            :member="item.invited_by"/>
        </td>
        <td v-if="item.last_login_at">
          {{ $date.$formatTimeAgo(item.last_login_at) }}
        </td>
        <td v-else>
          {{ $date.$formatTimeAgo(item.created_at) }}
        </td>
      </template>
    </CyDataTableYdApi>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import CyDataTableYdApi from '@/components/data-table-yd-api.vue'
import CyFormsAddTeamMembers from '@/components/forms/add-team-members.vue'

export default {
  name: 'CyTeamMembers',
  components: {
    CyDataTableYdApi,
    CyFormsAddTeamMembers,
  },
  props: {
    teamCanonical: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    showDeleteModal: false,
    toDelete: [],
  }),
  computed: {
    ...mapState('organization/team', {
      errors: (state) => state.errors,
    }),
    ...mapState('organization', {
      teamMembers: (state) => state.available.teamMembers,
    }),
    $static () {
      return {
        headers: [
          {
            text: this.$t('Member'),
            value: 'family_name',
            align: 'left',
          },
          {
            text: this.$t('invitedBy'),
            value: 'invited_by',
            align: 'left',
          },
          {
            text: this.$t('lastLogin'),
            value: 'last_login_at',
            align: 'left',
            width: 140,
          },
        ],
        searchableFields: [
          {
            name: 'given_name',
            label: this.$t('firstName'),
          },
          {
            name: 'family_name',
            label: this.$t('lastName'),
          },
        ],
      }
    },
    hasBulkModeEnabled () {
      const canDelete = this.$cycloid.permissions.canDisplay('UnassignMemberFromTeam')
      return canDelete && !_.isEmpty(this.teamMembers)
    },
  },
  methods: {
    ...mapActions('organization/team', [
      'UNASSIGN_TEAM_MEMBERS',
    ]),
    openDeleteModal (toDelete) {
      this.toDelete = toDelete
      this.$toggle.showDeleteModal(true)
    },
    closeDeleteModal () {
      this.$resetData('toDelete')
      this.$toggle.showDeleteModal(false)
    },
    async onDelete () {
      const { teamCanonical } = this
      await this.UNASSIGN_TEAM_MEMBERS({ teamCanonical, members: this.toDelete })
      this.closeDeleteModal()
      this.$refs.cyDataTable.retrieveItems({ clearErrors: false, clearSelected: true })
    },
  },
  i18n: {
    messages: {
      en: {
        areYouSure: 'Are you really sure you want to remove this @:team member? | Are you really sure you want to remove <b>{nb}</b> @:team members?',
        confirmRemoveTitle: '@:forms.btnRemove @:team member | @:forms.btnRemove @:team members',
        firstName: 'First name',
        invitedBy: 'Invited by',
        lastLogin: 'Last login',
        lastName: 'Last name',
        removeMembersBtn: '@:forms.btnRemove {nb} member | @:forms.btnRemove {nb} members',
      },
      es: {
        areYouSure: '¿Está realmente seguro de que desea eliminar este miembro del @:team? | ¿Está realmente seguro de que desea eliminar <b>{nb}</b> miembros del @:team?',
        confirmRemoveTitle: '@:forms.btnRemove miembro del @:team | @:forms.btnRemove miembros del @:team',
        firstName: 'Nombre',
        invitedBy: 'Invitado por',
        lastLogin: 'Último acceso',
        lastName: 'Apellido',
        removeMembersBtn: '@:forms.btnRemove {nb} miembro | @:forms.btnRemove {nb} miembros',
      },
      fr: {
        areYouSure: `Êtes-vous vraiment sûr de vouloir supprimer ce membre de l'@:team ? | Êtes-vous vraiment sûr de vouloir supprimer <b>{nb}</b> membres de l'@:team ?`,
        confirmRemoveTitle: `@:forms.btnRemove un membre de l'@:team | @:forms.btnRemove des membres de l'@:team`,
        firstName: 'Prénom',
        invitedBy: 'Invité-e par',
        lastLogin: 'Dernière connexion',
        lastName: 'Nom de famille',
        removeMembersBtn: '@:forms.btnRemove {nb} membre | @:forms.btnRemove {nb} membres',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
  .team-members__header {
    margin-bottom: 16px;
  }

  .default-avatar-placeholder {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }

  ::v-deep .cy-member {
    &__avatar.cy-avatar {
      margin-right: 8px;
    }

    .v-list__tile {
      padding-left: 0;
    }
  }
</style>
